import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from '../../../../../data/style/variables';
import { TitleH2, Paragraph } from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const AboutData: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
      >
        <Section>
          <TypographyAnimation>
            <TitleH2 color={Color.Black}>
              Knowledge is <Bold>power 💪</Bold>, dat geldt voor alle bedrijven
              tegenwoordig. Disc helpt bedrijven om hun data te visualiseren
              middels Power Bi. Dit kan gedaan worden voor uw bestaande software
              maar ook voor alle software die wij leveren.
            </TitleH2>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
