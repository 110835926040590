import React from "react"
import { ProductHero } from "../../../../../general/ProductHero/ProductHero"
import { PageWidget } from "../../../../../general/PageWidget/PageWidget"
import { useStaticQuery, graphql } from "gatsby"
import { Color } from "../../../../../../data/style/variables"
import {
  ContainerElement,
  LineContainerElement,
  LineImgElement,
  LineHolderElement
} from "./styled"
import lineSvg from "../../../../../../images/SVG/Group 4 (4).svg"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <LineContainerElement>
        <LineHolderElement>
          <LineImgElement src={lineSvg} />
        </LineHolderElement>
      </LineContainerElement>
      <ProductHero
        title="Data analyseren met Power Bi"
        background={Color.Black}
        technicalDetails="Data - Cloud - Power Bi - Business"
        infoTexts={["On Premise", "Cloud data", "Dashboards"]}
      />
    </ContainerElement>
  )
}
