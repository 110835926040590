import React from "react"
import { PowerBi as PowerBiComponent } from "../components/pages/PowerBi/PowerBi"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from "../data/style/variables"
import SEO from "../components/seo"

const PowerBi = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <SEO
      description="Knowledge is power, dat geldt voor alle bedrijven tegenwoordig. Disc helpt bedrijven om hun data te visualiseren middels Power Bi."
      title="Data analyseren met Power Bi"
      lang="nl"
    />
    <PowerBiComponent />
  </Layout>
)

export default PowerBi
