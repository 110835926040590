import React from "react";
import "typeface-roboto-mono";
import { Footer } from '../Home/sections/Footer/Footer';
import AboutData from "./sections/AboutData";
import { Hero } from './sections/Hero/Hero/Hero';
import { Info } from './sections/Info/Info';
import { ContainerElement } from "./styled";

export const PowerBi: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <AboutData/>
      <Info/>
      <Footer spacing/>
    </ContainerElement>
  )
}
