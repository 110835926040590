import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import {
  TitleH2,
  Paragraph,
  TitleH3,
} from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const Info: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "500px",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
      >
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>Data via onze apps.</TitleH3>
            <Paragraph color={Color.Black}>
              Alle data die in onze apps wordt verzameld kan worden weergegeven
              in Power Bi. We bouwen onze applicaties met dit in ons
              achterhoofd, hierdoor is <Bold>alles mogelijk</Bold>, alle acties worden
              opgeslagen.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Data vanuit bestaande software.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Heeft u een <Bold>bestaand softwarepakket</Bold> en wilt u uw data
              in Power Bi weergeven? Geen probleem wij kunnen u voorzien van een
              netwerk dat de data klaarzet voor Power Bi. Daarnaast kunnen we
              ook de dashboards leveren.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>Cloud en Gateways.</TitleH3>
            <Paragraph color={Color.Black}>
              Om uw data in Power Bi te krijgen moet u beschikken over een
              gateway. De gateway kan Disc realiseren en we leveren daar{" "}
              <Bold>24/7 service</Bold> voor.
            </Paragraph>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
