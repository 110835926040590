import styled from "styled-components/macro"

export const ContainerElement = styled.div`
    position: relative;
    overflow: hidden;
`
export const LineContainerElement = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 131px;
`
export const LineHolderElement = styled.div`
    max-width: 1280px;
`
export const LineImgElement = styled.img`
   width: 100%; 
`